@import url('https://fonts.googleapis.com/css?family=Source+Sans+Pro:200,300,400');
.pulse-button-bg,
.pulse-button {
  position: relative;
  width: 150px;
  height: 150px;
  border-radius: 100%;
  border: 2px solid #7abce6;
}

.pulse-button-ring {
  position: absolute;
  width: 150px;
  height: 150px;
  border-radius: 100%;
  padding: 5px;
  border: 2px solid #7abce6;
  z-index: 1;
}

.pulse-container {
  display: flex;
  width: 100%;
  height: 150px;
  margin: 0 auto;
  justify-content: center;
  align-items: center;
  margin-bottom: 1rem;
}

.pulse-button-bg {
  animation: pulse 3s ease infinite;
}

.pulse-button {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  z-index: 99;
  border: none;
  background: '#ff3466';
  background-size: 18px;
  cursor: pointer;
  outline: none;
}

.pulse-button div {
  position: absolute;
  color: gray;
  font-size: 17px;
}

@keyframes pulse {
  0% {
    transform: scale(1, 1);
  }
  50% {
    opacity: 0.5;
  }
  100% {
    transform: scale(1.2);
    opacity: 0;
  }
}
